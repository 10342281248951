import React, {Component} from 'react';
import '../ruleContent.css'
import { CKEditor } from 'ckeditor4-react';

class OtherRule extends Component {

    state={
        html:''
    }

    onEditorChange = (event)=>{
        // eslint-disable-next-line no-undef

         console.log(event.editor.getData())


        this.setState({
            // eslint-disable-next-line no-undef
            html:CKEDITOR.instances.editor1.getData()
        })
    }
   componentDidMount(){

   }

    render() {

        return (
            <div>
                <h1>补充规则:</h1>
                <div className={'textContent'}>
                    1、请勿给予新玩家高级材料（t3及以上）、高级设备等方式快速拉动其他玩家游戏进度。此行为为缩短其他玩家游戏周期寿命，不利于游戏体验和服务器长久发展。<br/>
                    2、请不要多人集中（20km间隔）驻扎，会严重导致服务器卡顿。服务器将会有强制的限制，以解决此造成的卡顿问题。请分家居住。<br/>
                    3、原则上，在服务器内禁止使用库存显示以外任何编程脚本。如自动驾驶、雷达等。会造成卡服。<br/>
                    请悉知。
                    4、针对所有规则/惩罚，服主会进行灵活处理(可能予以宽限/更严格的处罚)。以服主给出的处理结果为准。
                </div>
                <div dangerouslySetInnerHTML={{__html: this.state.html}}/>

                {/*<CKEditor id={'editor1'} onChange={this.onEditorChange} initData={<p>This is an  example CKEditor 4 WYSIWYG editor instance.</p>} />*/}
            </div>
        );
    }
}

export default OtherRule;